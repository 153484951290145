
import { Component, Vue } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import authService from '@/services/auth/authService';
import SuccessCheck from '@/components/SuccessCheck.vue';

@Component({
  components: {
    SuccessCheck,
  },
})
export default class PasswordVerifyForm extends Vue {
  public form: any = {
    email: '',
  };
  public showSuccessMessage = false;
  public error = '';
  public active = false;

  validations(): any {
    return {
      form: {
        email: { required, email },
      },
    };
  }

  async sendResetEmail(): Promise<void> {
    if (!this.$v.$invalid) {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      this.active = false;
      try {
        await authService.sendResetPasswordEmail(this.form.email);
        this.showSuccessMessage = true;
      } catch (err) {
        const { data } = err.response;

        this.error = Array.isArray(data.message) ? data.message[0] : data.message;
        this.active = true;
      } finally {
        loadingComponent.close();
      }
    }
  }
}
